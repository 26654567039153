import { useApi } from "api/API";
import AssignmentMap from "components/assignment/AssignmentMap";
import TableDetails from "components/assignment/TableDetails";
import Box from "components/Box";
import Spinner from "components/Spinner";
import { useMediaQuery } from "customhooks/useMediaQuery";
import useMount from "customhooks/useMount";
import { AssignmentTable, Mission } from "dtos";
import { Assignment, SiteInfo,WeatherInfo } from "dtos/Assignment";
import {
	loadingAssignmentAtom,
	loadingAssignmentErrorAtom,
} from "myrecoil/AssignmentState";
import { useEffect, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useHistory, useParams } from "react-router";
import { useRecoilState, useSetRecoilState } from "recoil";
import { selectedTable as selectedTableAtom } from "myrecoil/AssignmentState";
import TableNav from "components/assignment/TableNav";
import ReportActions from "components/assignment/ReportActions";
import { SortedLossesByProblem, getByInspectionStatus } from "helpers/AssignmentUtils";
import SetPowerPerAssignmentModal from "components/assignment/SetPowerPerAssignment";
import SetWeatherPerAssignmentModal from "components/assignment/SetWeatherPerAssignment";

const TABLE_IMAGES_URL = "https://images.moonlimit.com";

const AssignmentView: React.FC = () => {
	let history = useHistory();
	const breakpoints = useMediaQuery();
	const { id } = useParams<{ id: string | undefined }>();
	const [assignment, setAssignment] = useState<Assignment>();
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);
	const [mission, setMission] = useState<Mission | null>(null);
	const [curAssignment, setCurAssignment] = useState<Assignment | null>( null	);
	const [curWAssignment, setCurWAssignment] = useState<Assignment | null>( null	);

	const setLoadingAssignment = useSetRecoilState(loadingAssignmentAtom);
	const setLoadingAssignmentError = useSetRecoilState(
		loadingAssignmentErrorAtom
	);
	const [selectedTable, setSelectedTable] = useRecoilState(selectedTableAtom);

	const api = useApi();

	const savePower = (assignment: Assignment| null) => {
		api
			.patch(`assignments/${assignment?.id}/power`, assignment)
			.then(() => {
				setCurAssignment(null);
				if (assignment !== null) {
					if (!assignment.siteInfo) {
						assignment.siteInfo = {} as SiteInfo;
					}
					setAssignment(assignment);
					loadAssignment();
				}
			});
	};

	const saveWeather = (assignment: Assignment| null) => {
		api
			.patch(`assignments/${assignment?.id}/power`, assignment)
			.then(() => {
				setCurWAssignment(null);
				if (assignment !== null) {
					if (!assignment.weatherInfo) {
						assignment.weatherInfo = {} as WeatherInfo;
					}
					setAssignment(assignment);
					loadAssignment();
				}
			});
	};

	useEffect(() => {
		if (selectedTable && assignment) {
			let tableCandidate =
				assignment.areas[selectedTable.area].rows[selectedTable.row]
					.tables[selectedTable.col];
			if (tableCandidate && tableCandidate !== selectedTable.table) {
				setSelectedTable({ ...selectedTable, table: tableCandidate });
			}
		}
	}, [assignment, setAssignment]);

	let loadAssignment =  () =>
	{
		api.get(`assignments/${id}`)
			.then(({ data }) => {
				let assignment = data;
				if (assignment) {
					setAssignment(assignment);
					api.get(`missions`)
						.then(({ data }) => {
							if (Array.isArray(data)) {
								data.every((mission: Mission) => {
									if (mission.id === assignment.missionId) {
										try {
											setMission(mission);
										} catch (e) {
											console.log(e);
										}
										return false;
									}
									return true;
								});
							}
							setLoading(false);
						})
						.catch((e) => {
							setError(e.toString());
							setLoading(false);
						});
				}
			})
			.catch((e) => {
				setError(e.toString());
				setLoading(false);
			});

	}
	useMount(() => {
		loadAssignment();
	});

	const onViewPowerClick = (assignment: Assignment) => {
		setCurAssignment(assignment);
	};
	const onViewWeatherClick = (assignment: Assignment) => {
		setCurWAssignment(assignment);
	};

	let totalTables = 0;
	let totalPannels = 0;
	let brokenTables = 0;
	let brokenPanels = 0;
	let panelsByProblem: SortedLossesByProblem = {
		problems: [],
		total: {
			Count: 0,
			EffLoss: 0,
			PowerLoss: 0,
			EnergyLoss: 0,
			RevenueLoss: 0,
			panelPrice: 0,
			inCurrency: "",
			Name: "Total"
		}
	};

	if (!loading && assignment) {
		assignment.areas.forEach((area) => {
			area.rows.forEach((row) => {
				row.tables.forEach((table) => {
					totalTables++;
					if (table.inspectionStatus !== "Ok" && table.inspectionStatus !== "NotInspected") {
						brokenTables++;
					}
					table.panels.forEach((panel) => {
						if (panel.inspectionStatus !== "Ok" && panel.inspectionStatus !== "NotInspected") {
							brokenPanels++;
						}
						totalPannels++;
					});
				});
			});
		});
		panelsByProblem = getByInspectionStatus(assignment);
	}
	return (
		<>
			{loading ? (
				<Spinner mrgTop="10vh"></Spinner>
			) : error ? (
				<div style={{ width: "100%", textAlign: "center" }}>
					{error}
				</div>
			) : (
				assignment && (
					<div className="container box has-text-centered">
								{curAssignment && (
									<SetPowerPerAssignmentModal
										selectedAssignment={curAssignment}
										saveAssignment={savePower}
										closeModal={() => setCurAssignment(null)}
									></SetPowerPerAssignmentModal>
								)}
								{curWAssignment && (
									<SetWeatherPerAssignmentModal
										selectedAssignment={curWAssignment}
										saveAssignment={saveWeather}
										closeModal={() => setCurWAssignment(null)}
									></SetWeatherPerAssignmentModal>
								)}
						<div
							style={{
								textAlign: "start",
								marginBottom: "2vh",
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<button
								onClick={() => {
									history.push(
										`/inspections/${assignment.missionId}`
									);
								}}
								className="button is-info is-inverted"
							>
								<FaAngleLeft></FaAngleLeft>Back
							</button>
							<ReportActions
								assignment={assignment}
							></ReportActions>
						</div>
								<Box>
							<table className="table is-fullwidth">
								<thead>
									<tr>
										<th>Park</th>
										<th>Total Tables</th>
										<th>Total Panels</th>
										<th>Damaged Tables</th>
										<th>Damaged Panels</th>
										<th>Time Started</th>
										<th>Time Completed</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{mission?.name}</td>
										<td>{totalTables}</td>
										<td>{totalPannels}</td>
										<td style={{ color: "crimson" }}>
											{brokenTables}
										</td>
										<td style={{ color: "crimson" }}>
											{brokenPanels}
										</td>
										<td>
											{assignment.timeStarted
												? new Date(
														assignment.timeStarted
												  ).toLocaleDateString("en-GB")
												: ""}
										</td>
										<td>
											{assignment.timeCompleted
												? new Date(
														assignment.timeCompleted
												  ).toLocaleDateString("en-GB")
												: ""}
										</td>
									</tr>
								</tbody>
							</table>
						</Box>


						{assignment.areas && assignment.areas.length > 0 && (
							<div
								className={`is-flex  ${
									breakpoints.md
										? "is-flex-direction-column"
										: "is-flex-direction-row"
								}`}
							>
								<div
									className="box"
									style={{
										width: breakpoints.md ? "100%" : "50%",
										marginBottom: "0px",
									}}
								>
									<AssignmentMap
										assignment={assignment}
									></AssignmentMap>
								</div>

								<div
									className="box"
									style={{
										width: breakpoints.md ? "100%" : "50%",
										textAlign: "left",
										marginLeft: breakpoints.md
											? "0px"
											: "10px",
										marginTop: breakpoints.md
											? "10px"
											: "0px",
									}}
								>
									<TableNav
										areas={assignment.areas}
									></TableNav>
									<TableDetails
										imgPrefix={`${TABLE_IMAGES_URL}/${assignment.missionId}/${assignment.id}`}
										updateTable={(table: {
											table: AssignmentTable;
											area: number;
											row: number;
											col: number;
										}) => {
											setLoadingAssignment(true);
											let assignmentCopy: Assignment = {
												...assignment,
												areas: assignment.areas.map(
													(current, index) => {
														if (
															index === table.area
														) {
															let areaCopy = {
																...current,
															};
															areaCopy.rows[
																table.row
															].tables[
																table.col
															] = table.table;
															return areaCopy;
														} else {
															return current;
														}
													}
												),
											};
											api.patch(
												`assignments/${id}`,
												assignmentCopy
											)
												.then(({ data }) => {
													setAssignment(data);
													setLoadingAssignment(false);
												})
												.catch((e) => {
													setLoadingAssignmentError(
														e.toString()
													);
													setLoadingAssignment(false);
												});
										}}
									></TableDetails>
								</div>
							</div>
								)}
								<Box>
									<table className="table is-fullwidth">
										<thead>
											<tr>
												<th>Modules by anomaly type</th>
												<th>Count</th>
												<th>Est. Module Efficiency Loss<sup style={{fontSize: "small"}}>1</sup></th>
												<th>Est. Power Loss<sup style={{fontSize: "small"}}>2</sup><br/>(kW)</th>
												<th>Est. Annual Energy Loss<sup style={{fontSize: "small"}}>3</sup><br/>(kWh)</th>
												<th>Est. Annual Revenue Loss<sup style={{fontSize: "small"}}>4</sup><br/>
													{panelsByProblem.total.panelPrice} {panelsByProblem.total.inCurrency} per kWh</th>
											</tr>
										</thead>
										<tbody>
											{panelsByProblem.problems.map(item => { return (
											<tr>
												<td align="left">{item.losses.Name}</td>
												<td align="right">{item.losses.Count}</td>
												<td align="right">{item.losses.EffLoss.toFixed(0)}%</td>
												<td align="right">{item.losses.PowerLoss.toFixed(2)}</td>
												<td align="right">{item.losses.EnergyLoss.toFixed(2)}</td>
												<td align="right">{item.losses.RevenueLoss.toFixed(2)}</td>
											</tr>
											)})}
											<tr>
												<td align="left">Total</td>
												<td align="right">{panelsByProblem.total.Count}</td>
												<td align="right"></td>
												<td align="right">{panelsByProblem.total.PowerLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem.total.EnergyLoss.toFixed(2)}</td>
												<td align="right">{panelsByProblem.total.RevenueLoss.toFixed(2)}</td>
											</tr>
										</tbody>
									</table>
									<button
										className="button"
										onClick={() => onViewPowerClick(assignment)}
									>
										Edit Power Settings
									</button>
									<button
										className="button"
										onClick={() => onViewWeatherClick(assignment)}
									>
										Edit Weather Settings
									</button>
									<div style={{textAlign: "left", fontSize: "small"}}>
										<sup>1</sup> Estimated efficiency loss per module for the anomaly type.<br/>
										<sup>2</sup> Estimated power loss for all modules affected by the anomaly. The value is computed by multiplying number of modules affected, the peak rated power of the module, and the estimated efficiency loss for the anomaly type.<br/>
										<sup>3</sup> Estimated annual energy loss for all modules affected by the anomaly assuming an average of 4 peak sunlight hours per day annually.<br/>
										<sup>4</sup> Estimated annual revenue loss for all modules affected by the anomaly assuming {panelsByProblem.total.panelPrice} {panelsByProblem.total.inCurrency} per kWh.<br/>
									</div>
								</Box>
					</div>
				)
			)}
		</>
	);
};

export default AssignmentView;
