import { AssignmentPanel, AssignmentTable } from "dtos";
import { getInspectionStatusLabel, getInspectionStatusNLabel } from "helpers/AssignmentUtils";
import { selectedTable } from "myrecoil/AssignmentState";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import PanelsDetails from "./PanelsDetails";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

interface Props {
	imgPrefix?: string;
	updateTable: (tableWrapper: {
		table: AssignmentTable;
		area: number;
		row: number;
		col: number;
	}) => void;
}


const TableDetails: React.FC<Props> = ({ imgPrefix, updateTable }) => {
	const [table] = useRecoilState(selectedTable);
	const [irImage, setIRImage] = useState<number>(0);
	const [rgbImage, setRGBImage] = useState<number>(0);

	const round = (num: number) => {
		return Math.round(num * 100) / 100;
	};

	useEffect(() => {
		setIRImage(0);
		setRGBImage(0);
	}, [table]);

	const prevRGBImage = () => {
		if (table)
		{
			setRGBImage((table.table.rgb_Images + rgbImage - 1) % table.table.rgb_Images);
		}
	};
	const prevIRImage = () => {
		if (table)
		{
			setIRImage((table.table.ir_Images + irImage - 1) % table.table.ir_Images);
		}
	};
	const nextRGBImage = () => {
		if (table)
		{
			setRGBImage((rgbImage + 1) % table.table.rgb_Images);
		}
	};
	const nextIRImage = () => {
	if (table)
		{
			setIRImage((irImage + 1) % table.table.ir_Images);
		}
	};

	return (
		<>
			{table && (
				<div>
					<span
						style={{
							fontSize: "1.25rem",
							marginRight: "5px",
						}}
					>
						<b>Lat: </b>
						{table.table.gps_Location.lat}{" "}
						<p style={{ display: "inline-block" }}>
							<b>Lng: </b>
							{table.table.gps_Location.lng}
						</p>
					</span>
					<p
						style={{
							fontSize: "1.25rem",
							marginRight: "5px",
						}}
					>
						<b>Table inclination: </b>
						{round(table.table.inclination_Deg)}&deg;
					</p>
					<p
						style={{
							fontSize: "1.25rem",
							marginRight: "5px",
						}}
					>
						<b>Panels inspection status: </b>
						<span
							className={`tag is-outlined is-light is-large ${
								getInspectionStatusNLabel(table.table) === 0
									? "is-primary"
									: "is-danger"
							}`}
						>
							{getInspectionStatusNLabel(table.table)} damaged panels
						</span>
					</p>
					{table.table.ir_Images === 0 &&
					table.table.rgb_Images === 0 ? (
						<div>
							<span
								style={{
									marginBottom: "0.5em",
									paddingLeft: "0px",
								}}
								className={`tag is-outlined is-light is-large is-danger`}
							>
								Images aren't uploaded yet.
							</span>
						</div>
					) : (
						<div><table>
							{table.table.rgb_Images > 0 && (
							<tr>
								<td style={{width:"1%", verticalAlign:"middle"}}>
									{rgbImage > 0 && (
										<button
											onClick={prevRGBImage}
											className="button is-info is-inverted"
											title="Previous RGB image">
											<FaAngleDoubleLeft></FaAngleDoubleLeft>
										</button>
									)}
									{rgbImage <= 0 && table.table.rgb_Images > 1 && (
										<button
											disabled
											className="button is-info is-inverted"
											title="Previous RGB image">
											<FaAngleDoubleLeft></FaAngleDoubleLeft>
										</button>
									)}
								</td>
								<td><img title={`Image ${rgbImage+1} of ${table.table.rgb_Images}`} src={`${imgPrefix}/table_${table.table.id}_RGB_${rgbImage}.png`} /></td>
								<td style={{width:"1%", verticalAlign:"middle"}}>
									{rgbImage + 1 < table.table.rgb_Images && (
										<button
											onClick={nextRGBImage}
											className="button is-info is-inverted"
											title="Next RGB image">
											<FaAngleDoubleRight></FaAngleDoubleRight>
										</button>
									)}
									{rgbImage + 1 >= table.table.rgb_Images && table.table.rgb_Images > 1 && (
										<button
											disabled
											className="button is-info is-inverted"
											title="Next RGB image">
											<FaAngleDoubleRight></FaAngleDoubleRight>
										</button>
									)}
								</td>
							</tr>
							)}
							{table.table.ir_Images > 0 && (
							<tr>
								<td style={{width:"1%", verticalAlign:"middle"}}>
									{irImage > 0 && (
										<button
											onClick={prevIRImage}
											className="button is-info is-inverted"
											title="Previous IR image">
											<FaAngleDoubleLeft></FaAngleDoubleLeft>
										</button>
									)}
									{irImage <= 0 && table.table.ir_Images > 1 && (
										<button
											disabled
											className="button is-info is-inverted"
											title="Previous IR image">
											<FaAngleDoubleLeft></FaAngleDoubleLeft>
										</button>
									)}
								</td>
								<td><img title={`Image ${irImage+1} of ${table.table.ir_Images}`} src={`${imgPrefix}/table_${table.table.id}_IR_${irImage}.png`} /></td>
								<td style={{width:"1%", verticalAlign:"middle"}}>
									{irImage + 1 < table.table.ir_Images && (
										<button
											onClick={nextIRImage}
											className="button is-info is-inverted"
											title="Next IR image">
											<FaAngleDoubleRight></FaAngleDoubleRight>
										</button>
									)}
									{irImage + 1 >= table.table.ir_Images && table.table.ir_Images > 1 && (
										<button
											disabled
											className="button is-info is-inverted"
											title="Next IR image">
											<FaAngleDoubleRight></FaAngleDoubleRight>
										</button>
									)}
								</td>
							</tr>
							)}
						</table></div>
					)}
					{table.table.status !== "ABANDONED" && (<PanelsDetails
						key={table.table.id}
						imgPrefix={imgPrefix!}
						panels={table.table.panels}
						panelsLayout={table.table.panelsLayout}
						updatePanel={(panel: AssignmentPanel) => {
							let damagedPanels = 0;
							let uninspectedPanels = 0;
							let totalPanels = table.table.panels.length;
							let panels = table.table.panels.map((pan) => {
								if (pan.id == panel.id)
								{
									pan.inspectionStatus = panel.inspectionStatus;
								}
								if (pan.inspectionStatus !== "Ok" && pan.inspectionStatus !== "NotInspected")
								{
									damagedPanels++;
								}
								if (pan.inspectionStatus === "NotInspected")
								{
									uninspectedPanels++;
								}
								return pan;
							});
							let newTable = {
								...table,
								table: {
									...table.table,
									panels,
									panels_Complete: totalPanels - damagedPanels,
									panels_Failed: damagedPanels,
									panels_Total: totalPanels,
								},
							};
							if (uninspectedPanels > 0)
							{
								newTable.table.inspectionStatus = "NotInspected";
							}
							else if (damagedPanels > 0)
							{
								// TODO: separate table inspection status from panel inspection status
								newTable.table.inspectionStatus = "PANEL_BROKEN_OTHER";
							}
							else
							{
								newTable.table.inspectionStatus = "Ok";
							}
							if (newTable.table.ir_Images > 0)
							{
								newTable.table.irStatus = "Done";
							}
							if (newTable.table.rgb_Images > 0)
							{
								newTable.table.irStatus = "Done";
							}
							updateTable(newTable);
						}}
					></PanelsDetails>)}
				</div>
			)}
		</>
	);
};

export default TableDetails;
